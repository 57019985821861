 .report_test-row{
    position:fixed;
    background-color:#e9f5e4;
    height: 70px;
    top:76px;
    width:100%;
    align-items: center;
}

.reportList-table{
 margin-top: 146px;
} 

.reportList-table tbody td,
.reportList-table thead th{
    vertical-align: middle !important;
}

.exe-report{
  color: red;
  margin-left: 2.5rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.zIndex{
  z-index: -1;
}
.TC-bulk-btn-TSR{
  border-color: none;
  border-style: none;
  border-radius: 4px;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  background: #7eb965;
  margin-top: 18px;
  max-width: 150px;
  min-width: 150px;
  align-content: center;
  height: 30px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  padding-inline: 15px;
}

.dropdown-item{
  padding-inline: 0 !important;
}

.btn-group .dropdown-menu li:hover{
  background-color: #7eb965 !important;
}

.dropdown-menu{
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;
  min-width: 150px;
  max-height: 100px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.dScroll {
    max-height: 130px !important;
    overflow-y: scroll;
    overflow-x: hidden !important;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    /* padding-inline: 0 !important; */
 }