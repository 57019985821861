.TC-crt-sec-head {
	color: #805382;
	font-weight: bold;
	margin-left: 75px;
}

.first-head {
	font-weight: bold;
	margin-left: 75px;
	color: #7eb965 !important;
}

.pre {
	padding: none !important;
}
@media only screen and (max-width: 1500px) {
	.first-head,
	.TC-crt-sec-head {
		margin-left: 65px;
	}
}

.TC-crt-user-story-menus,
Form.Label {
	font-weight: bold;
	font-size: 15px;
}

@media all and (max-width: 1500px) {
	.TC-crt-user-story-menus Label {
		font-size: 13.5px !important;
	}
}

@media screen and (max-width: 1500px) {
	.TC-crt-user-story-menus select {
		height: 33px;
	}
}

@media screen and (max-width: 1500px) {
	.TC-crt-user-story-menus .create-form {
		height: 33px !important;
		font-size: 13px !important;
	}
}

.bulk-btn {
	border-color: none;
	border-style: none;
	border-radius: 4px;
	color: #fff;
	font-weight: 400;
	font-size: 12px;
	background: #7eb965;
	width: 130px;
	align-content: center;
	height: 30px;
}

.app-btn {
	border-color: none;
	border-style: none;
	border-radius: 4px;
	color: #fff;
	font-weight: 400;
	font-size: 12px;
	background: #7eb965;
}

.create-testcase-btn {
	margin-left: 5px;
	width: 160px;
	height: 30px;
	margin-right: 20px;
	background-color: #7eb965;
	color: white;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	gap: 10px;
	border: none;
	border-radius: 5px;
	opacity: 1;
}

.create-testcase-btn:hover {
	background: #65a24a;
	color: white;
}

/*here starting editor bottom buttons css*/

.edit-save-as {
	color: #fff;
	font-weight: bold;
	background-color: #805382;
	border-color: none;
	border-radius: 4px;
}

.edit-save-as:hover {
	color: #fff;
	font-weight: bold;
	background-color: #5D3B5E !important;
}

.edit-publish {
	border-color: none;
	border-radius: 4px;
	color: #fff;
	font-weight: bold;
	background-color: #7eb965 !important;
	padding: 6px 25px;
}

.edit-publish:hover {
	border-color: none;
	border-radius: 4px;
	color: #fff;
	font-weight: bold;
	background-color: #5d874c !important;
}

.edit-cancel {
	border-color: none;
	border-radius: 4px;
	color: #fff;
	font-weight: bold;
	background: rgb(179, 177, 177) !important;
	padding: 6px 25px;
}

.edit-cancel:hover {
	border-color: none;
	border-radius: 4px;
	color: #fff;
	font-weight: bold;
	background-color: #5c5b5b !important;
} 

/*here ending editor bottom buttons css*/

.TC-crt-user-story-menus {
	margin-left: 27px;
	margin-top: 10px;
}

@media only screen and (max-width: 1500px) {
	.TC-crt-user-story-menus {
		margin-left: 18px;
	}
}

.TC-crt-user-story-menus Form.Select {
	margin-left: 25px;
	border: 1px solid #9f9f9f;
}

.crt-editor-head {
	color: #333333;
	font-weight: bold;
	padding: 0;
	margin-top: 7px;
}

.crt-react-editors {
	font-family: Montserrat;
	font-size: 14px;
	border: 1px #9f9f9f solid !important;
	border-radius: 5px;
	font-size: 14px;
	width: 100%;
	/* height: 72vh; */
}

.TC-crt-user-story-menus .error {
  color: red;
  font-size: 0.9rem;
  font-weight: 500;
  margin-top: 0.3rem;
  display: flex;
  justify-content: flex-start;
}

.TC-error{
	color: red !important;
	font-size: 0.9rem;
  font-weight: 500;
  margin-top: 0.3rem;
  display: flex;
  justify-content: flex-start;
}

@media all and (max-width: 1500px) {
	/* .crt-react-editors {
    height: 72.3vh;
  } */

	.crt-editor-head {
		font-size: 14px;
	}
}

.create_test_Cfluid {
	margin-top: 160px;
}

.createtest-test-row {
	position: fixed;
	background-color: #e9f5e4;
	height: 64px;
	top: 76px;
	width: 100%;
	align-items: center;
	z-index: 2;
}

.createtest_container {
	margin-top: 40px;
}

.edit-clone,
.editor-code {
	height: 95%;
}

/* .crt-react-editors pre{
  display: none !important;
}

.crt-react-editors textarea{
 -webkit-text-fill-color: rgb(43, 42, 42) !important;
} */

.Editor-bottom-btns button {
	border: none !important;
}
.editor {
	counter-reset: line;
	border: 1px solid #ced4da;
  }
  
  .editor #codeArea {
	outline: none;
	padding-left: 60px !important;
  }
  
  .editor pre {
	padding-left: 60px !important;
  }
  
  .editor .editorLineNumber {
	position: absolute;
	left: 0px;
	color: #cccccc;
	text-align: right;
	width: 40px;
	font-weight: 100;
  }
  
