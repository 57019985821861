/* .summary-one,
.summary-sec,
.summary-third,
.summary-chart
p {
  font-size: 16px !important;
  color: #555555;
}

.summary-one,
.summary-sec,
.summary-third,
.summary-chart h4,
h6 {
  font-size: 15px;
  color: #999999;
}

@media only screen and (max-width:992px) {
  .summary-one,
  .summary-sec,
  .summary-third,
  .summary-chart h4,
  h6,
  p {
    font-size: 14px;
  }
} */

/* Mail Drop CSS*/

/* .mail-drop {
  height: 35px;
  border: 1px solid rgb(188, 187, 187);
  width: 230px;
  overflow: hidden;
}
}

*/

.items img {
  background: #7eb965;
}

.mail-send {
  /* width: 28px; */
  color: white;
  height: 27px;
  cursor: pointer;
  border-radius: 4px;
  background: transparent #7eb965 0% 0% no-repeat padding-box !important;
}

.send-Mail {
  border-radius: 15px 15px 0px 0px !important;
}

.dropdown-check-list {
  display: block;
  background: #fff;
  border-radius: 0px 3px 3px 0px !important;
}

.dropdown-check-list .anchor {
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 5px 50px 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px 5px 5px 5px
}

.testcase-report-nav{
    position : fixed;
    margin-top: 36px;
}

.dropdown-check-list .anchor:after {
  position: absolute;
  content: "";
  border-left: 2px solid black;
  border-top: 2px solid black;
  padding: 5px;
  right: 10px;
  top: 20%;
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.dropdown-check-list .anchor:active:after {
  right: 8px;
  top: 21%;
}

.dropdown-check-list ul.items {
  padding: 2px;
  display: none;
  margin: 0;
  border: 1px solid #ccc;
  border-top: none;
  position: absolute;
  width: 219px;
  background: #fff;
  height: 100px;
  border-radius: 0px 0px 5px 5px;
  word-wrap: break-word;
}

.dropdown-check-list ul.items li {
  list-style: none;
}

.dropdown-check-list.visible .items {
  display: block;
}

/* Mail Drop CSS*/
.TC-repo-Execution-main-head {
  max-width: 100%;
}

.TC-repo-Execution-sum-head {
  overflow-y: hidden;
  overflow-x: hidden;
}

.report-head {
  border: 1px solid grey;
  border-radius: 4px;
}

.TC-repo-table-header {
  width: 100%;
  margin: 20px 0px 0px 68px !important;
  padding-inline: 32px;
}

.report_sidebar-close {
  display: none;
}

.summary-chart {
  border-left: 2px solid rgb(218, 216, 216);
  align-items: center;
}

.summary-chart div {
  margin-right: 50px;
}

/* @media only screen and (min-width: 992px) {

  .summary-chart h4,
  .summary-sec,
  .summary-third,
  Execution-sum-head {
    font-size: 18px;
  }
} */

/* Here starting the table CSS*/
.repo-thead{
  z-index: -1;
  position: relative;
}

.TC-repo-table-header>.repo-thead,
th {
  background-color: #f9f1f9 !important;
  font-size: 15px;
  font-style: Montserrat;
  height: 40px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  border-right: solid;
  /* z-index: -1;
  position: relative; */
}

tbody {
  font: normal normal 12px/15px Montserrat;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
}

.TC-repo-head_title {
  text-align: left;
  font: normal normal bold 16px/20px Montserrat;
  letter-spacing: 0px;
  color: #805382;
  margin-top: 170px !important;
  margin-left: 78px;
  /* z-index:1; */
  overflow: visible;
}

.report-icons {
  display: flex;
  gap: 10px;
}

.svg-icon {
  color: white;
}

.reportIcon-download{
  background-color:#e9f5e4 !important;
}

.TC-repo-testcase-report-table th span{
  cursor: pointer !important;
}

.reportIcon-download:hover {
  background: #7eb965 !important;
  color: #fff !important;
  border-radius: 5px;
}

.report_sidebar {
  border: 1px solid grey;
  border-radius: 4px;
  overflow-y: auto;
  overflow-x: auto;
  height: 90%;
  margin: 0;
  padding: 0;
  min-height: 90%;
  /* z-index: -1;
  position: relative; */
}

.report_sideTable {
  width: 100%;
  max-height: 60vh;
}

.report-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.TC-repo-testcase-report-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.TC-repo-testcase-report-table{
  width:97% !important;
  border: 1px solid grey;
  border-radius: 2px;
  margin-top: 170px;
}


.report_sideTable tr:nth-child(even) {
  background-color: #f9f9f9;
}

.report_sideTr {
  display: flex;
  gap: 10px;
}

.report_sideTd {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  font-size: 14px;
  line-height: 2.5;
  /* padding-top: 10px; */
  height: 120px;
  color: #727272;
}

.td-1 {
  font-size: 40px;
  color: #999999;
  margin-left: 10px;
}

.report_img {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.TC-bulk-btn-TCR{
  border-color: none;
  border-style: none;
  border-radius: 4px;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  background: #7eb965;
  margin-top: 18px;
  max-width: 150px;
  min-width: 150px;
  align-content: center;
  height: 30px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  padding-inline: 15px;
}

.dropdown-menu{
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;
}

.dropdown-item{
  /* max-width: 150px; */
  padding-inline: 0;
}

.dropdown-item:hover{
  background-color: #7eb965;
  color: #ccc;
}

/* .report_screenshot {
  display: flex;
  margin-left: 55px;
  margin-top: 35px;
  justify-content: flex-start;
  align-items: center;
  font: normal normal medium 14px/18px Montserrat;
  letter-spacing: 0px;
  color: #727272;
} */

.report-logs {
  border: 1px solid grey;
  border-radius: 4px;
  margin-top: 10px;
  box-shadow: #727272;
  padding: 10px;
}

.report-logs>button {
  gap: 10px;
  font-family: inherit;
  font-size: 13px;
  font-weight: 500;
  /* text-transform: uppercase; */
  letter-spacing: 0.4px;
  color: #805382;
  background: #7eb96526;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: rgba(255, 255, 255, 0.333);
  border-radius: 40px 40px 40px 40px;
  padding: 12px 20px 10px 20px;
  transform: translate(0px, 0px) rotate(0deg);
  transition: 0.2s;
  box-shadow: -4px -2px 16px 0px #ffffff, 4px 2px 16px 0px rgb(95 157 231 / 48%);
}

.report-logs>button:hover {
  color: #ffffff;
  background-color: #7eb965;
  box-shadow: -2px -1px 8px 0px #ffffff, 2px 1px 8px 0px rgb(95 157 231 / 48%);
}

.report-logs>button:active {
  box-shadow: none;
}

.k-pdf-export {
  height: 790px;
}

.k-pdf-export div h4 {
  font-size: 10px !important;
}

.k-pdf-export div h6 {
  font-size: 8px !important;
}

.k-pdf-export div p {
  font-size: 8px !important;
}

.k-pdf-export div img {
  width: 12px !important;
}

.k-pdf-export div table {
  width: 550px !important;
}

.k-pdf-export div table thead tr {
  font-size: 10px !important;
}

.k-pdf-export div table tr {
  font-size: 8px !important;
  height: 20px;
}

.TC-repo-sidebar-main-header {
  font: normal normal bold 16px/20px Montserrat;
  letter-spacing: 0px;
  color: #805382;
  margin-top: 182px;
  width: 32%;
  margin-left: 15px;
  z-index: -1;
}

 .dScroll {
    max-height: 100px;
    overflow-y: scroll;
    overflow-x: hidden !important;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    padding-inline: 0 !important;
 }

@media only screen and (min-width: 1200px) {
  .TC-repo-sidebar-main-header {
    width: 32%;
    margin-left: 10px;
  }
}

@media only screen and (min-width: 1500px) {
  .TC-repo-sidebar-main-header {
    width: 32%;
    margin-left: 12px;
  }
  .TC-repo-head_title{
    margin-left: 88px;
  }
}

@media only screen and (max-width: 1500px){
    .TC-repo-table-header{
    margin-left: 55px !important;
    padding-inline: 35px !important;
  }
}

@media only screen and (min-width: 1400px){
    .TC-repo-table-header{
    margin-left: 62px !important;
    padding-inline: 40px !important;
  }
}

@media only screen and (max-width:1400px) {
    .td-1 {
  font-size: 30px !important;
  }
}

.report-test-row{
position: fixed;
  background-color: #e9f5e4;
  height: 70px;
  top: 76px;
  width: 100%;
  align-items: center;
  z-index:999;
}