@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600&display=swap");


* {
  box-sizing: border-box;
}

.sidebar {
  top: 0;
  margin: 0;
  padding: 0;
  width: 60px;
  position: fixed;
  height: 100%;
  background-color: #805382;
  display: flex;
  flex-direction: column;
  gap: 38px;
  z-index: 999;
  font: Montserrat;
}

@media only screen and (max-width: 1500px) {
  .sidebar {
    width: 50px;
  }

  .side-nav.close .nav-links li .sub-menu {
    width: 200px !important;
    left: 45px !important;
    height: 45px !important;
  }

  .side-nav.close .nav-links li .sub-menu .link_name {
    height: 45px !important;
    font-size: 12px !important;
  }


  .side-nav .nav-links li img {
    margin-right: 10px;
    width: 45px !important;
    height: 45px !important;
  }

  .test-case {
    width: 200px !important;
    height: 45px !important;
    font-size: 12px !important;
    background-color: #805382;
  }

  .side-nav .nav-links .nav-img .sub-menu li img {
    width: 45px !important;
    height: 45px !important;
  }

  .side-nav .nav-links .nav-img {
    margin-right: 10px;
    width: 45px !important;
    height: 45px !important;
  }

}

body {
  margin: 0;
  font-family: Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dash {
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.logo {
  display: flex;
  flex-direction: column;
  gap: 23px;
  float: left;
}

/* 
#log-icons>img>span {
  display: none;
}

#log-icons:hover>img>span {
  display: inline-block;
}

#dash-icon {
  background-color: #805382;
}

#test-icon {
  background-color: #805382;
}

#object-icon {
  background-color: #805382;
}

#schedule-icon {
  background-color: #805382;
} */

/* sidebar */

.nav-links {
  height: 100%;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 25px;
}


.side-nav {
  margin-top: -40px;
}

.side-nav .nav-links li {
  position: relative;
  list-style: none;
  transition: all 0.4s ease;
}

/* .side-nav .nav-links li:hover {
  background-color: #600666;
} */

.side-nav .nav-links li img {
  height: 50px !important;
  /* width: 80px !important;
  margin-right: 10px; */
  font-size: 20px;
  padding: 10px;
}

.side-nav .nav-links li .sub-menu li img {
  height: 50px;
  width: 50px;
  text-align: center;
  font-size: 10px;
  padding: 10px;
}

.side-nav .nav-links li a {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  width: 250px;
}

.side-nav .nav-links li a .link_name {
  text-align: center;
  padding: 10px;
  font-weight: 100;
}

/* .side-nav .nav-links li .sub-menu {
  padding: 6px 6px 14px 80px;
  margin-top: 5px;
  background: #805382;
} */

.side-nav .nav-links li .sub-menu Link {
  font-size: 5px;
  padding-right: 1px;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.3s ease;
}

.side-nav .nav-links li .sub-menu Link:hover {
  opacity: 1;
}

.side-nav.close .nav-links li .sub-menu {
  position: absolute;
  left: 50px;
  top: 0;
  padding: 0;
  border-radius: 0;
  transition: all 0.5s ease;
  opacity: 0;
  pointer-events: none;
  background-color: #805382;
  width: 250px;
  color: #fff;
}

.side-nav.close .nav-links .link_names .sub-menu:hover {
  background: #4c204e;
  color: #fff;
}

/* 
hr {
  height: 1px;
  border-width: 0;
  color: rgb(223, 223, 223);
  background-color: rgb(175, 175, 175);
} */
/* .sub-menu li {
  height: 50px;
  width: 80px;
} */

.side-nav .nav-links li .sub-menu .link_name {
  display: none;
} 

.side-nav.close .nav-links li:hover .sub-menu {
  top: 0;
  opacity: 1;
  z-index: 10;
  pointer-events: auto;
}



.side-nav.close .nav-links li .sub-menu .link_name {
  font-size: 13px;
  font-weight: 500;
  text-shadow: none;
  opacity: 1;
  display: flex;
}

/* .nav-links li:hover {
  height: 20px;
  width: 20px;
  text-align: center;
  font-size: 5px;
  padding: 10px;
} */

/* .sub-menu li {
  width: 250px;
  background: #805382;
  margin-left: 64px;
  opacity: 1;
}

.sub-menu li:hover {
  background: #5D3B5E;
} */

.test-case {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  font-size: 13px;
  text-shadow: none;
  font-weight: 500;
  opacity: 1;
  width: 250px;
  background-repeat: no-repeat;
  border-bottom: 1px solid grey;
}

.test-case:hover {
  background-color: #5D3B5E;
  opacity: 1;
}

.close:focus,
.close:hover {
  opacity: 1;
}

.IQA-version h5{
  font-size: 12px;
  font-weight: 400 !important;
  margin-left: 15px;
}