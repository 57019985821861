.TS-Canvas-create-head {
  background-color: #F0E3F1;
  margin-top: 12.2rem;
  width: 40% !important;
}

.create-label {
  margin-left: 20px;
  font-weight: bold !important;
}

.ts-form,
.select-ts-env,
.browser-version,
.suite-buttons {
  margin-left: 20px;
  width: 95% !important;
}

.TS-Canvas-create-head .error {
  color: red;
  font-size: 0.9rem;
  font-weight: 500;
  margin-top: 0.3rem;
  display: flex;
  justify-content: flex-start;
  margin-left: 1.3rem !important;
}

.TS-Canvas-create-head .TSCR-error{
  color: red;
  font-size: 0.9rem;
  font-weight: 500;
  margin-top: 0.3rem;
  display: flex;
  justify-content: flex-start;
  margin-left: 0.2rem;
}

/* .edit-testsuite {
  background-color: #f0e3f1;
  margin-top: 184px;
  margin-left: 40px;
  width: 50em;
  height: 100%;
} */

.browser-icon {
  /* display: flex; */
  margin-left: 20px;
  gap: 10px;
}

.suite-buttons {
  display: flex;
  margin-top: 30px;
  gap: 20px;
}

.save-ts {
  width: 128px;
  height: 40px;
  border: none;
  background-color: #7eb965;
  border-radius: 5px;
  color: white;
}

.save-ts:hover{
  background-color: #5d874c;
}

.cancel-ts {
  width: 128px;
  height: 40px;
  border: none;
  background-color: #9f9f9f;
  border-radius: 5px;
  color: white;
}

.cancel-ts:hover{
  background-color: #5c5b5b;
}

.text-area:hover {
  height: 100px;
}

.ts-create {
  text-align: center;
  color: #805382;
  font-size: 16px;
  font: normal normal bold 16px/19px Montserrat;
}



.ts-form input[type="search"] {
  margin: 0;
  height: 38px;
  font-size: 14px;
}

input[type="search"]::placeholder {
  color: #cccccc;
  font-size: 14px;
  padding-left: 20px;
}

.search-icon img {
  position: absolute !important;
}

.search-i {
  margin-top: 10px;
}

form.search-bar {
  border: none;
  padding: 0;
  /* background: #000; */
}

.search-bar {
  display: block;
  padding: 9px 4px 9px 20px;
  background: #fff url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
  padding-left: 30px !important;
}

.link-search {
  padding: 12px;
  color: #cccccc;
  text-align: left;
  margin-right: 10px;
}

.input-field {
  /* width: 100%; */
  font-size: 20px;
  font-weight: 500;
}

.dropdown {
  position: relative;
  display: inline-block;
}

/* .dropdown-content {
  display: none;
  margin-top: 7px;
  position: absolute;
  background-color: white;
  width: 820px;
  z-index: 1;
  cursor: pointer;
} */

/* .dropdown-content p {
  color: black;
  padding: 20px 16px;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-content {
  display: block;
} */

input[type="checkbox"] {
  accent-color: #805382;
}

.ts-env {
  /* width: 844px; */
  height: 38px;
  border-radius: 5px;
  border: 1px solid #9f9f9f;
  margin-left: 40px;
}

.version-select {
  width: 95% !important;
  height: 38px;
  border: 1px solid #9f9f9f;
  border-radius: 5px;
}

/*responsive queries*/

@media all and (max-width:1500px) {
  .TS-Canvas-create-head {
    width:35% !important;
  }
  
  .TS-Canvas-create-head Label, .select-ts-env option {
    font-size: 13.5px !important;
  }
}

@media screen and (max-width:1500px) {
  .TS-Canvas-create-head select {
    height: 33px;
    font-size: 13px;
  }
}

@media screen and (max-width:1500px) {
  .TS-Canvas-create-head .TS-create-form {
    height: 33px !important;
    font-size: 13px !important;
  }

  .suite-buttons button, .save-ts {
    height: 30px !important;
    width: 110px !important;
  }
  .Create-TS-li{
    font-size: 13px !important;
  }
}


.test-suite-create-btn {
  /* margin-left: 5px; */
  width: 140px;
  height: 30px;
  margin-right: 20px;
  background: #7eb965;
  color: white;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  gap: 10px;
  border: none;
  border-radius: 5px;
  opacity: 1;
  margin-top: 17px;
}

.test-suite-create-btn:hover {
  background: #5D874C;
  color: white;
}

.test-suite-create-btn:focus {
    outline: 0 !important;
}
