.TS-Canvas-edit-head {
  margin-top: 12.2rem;
  width: 40% !important;
  background: #f0e3f1;
}

.edit-label {
  margin-left: 20px;
  font-weight: bold;
}

.browser-label {
  font-weight: bold;
}

.ts-form,
.select-ts-env,
.browser-version,
.suite-buttons {
  margin-left: 20px;
  width: 93% !important;
}

.TS-Canvas-edit-head .error {
  color: red;
  font-size: 0.9rem;
  font-weight: 500;
  margin-top: 0.3rem;
  display: flex;
  justify-content: flex-start;
  margin-left: 1.3rem !important;
}
/* .testsuite-slider {
  background-color: #f0e3f1;
  margin-top: 184px;
  width: 100%;
} */
/* .edit-testsuite {
  background-color: #f0e3f1;
  margin-top: 184px;
  margin-left: 40px;
  width: 50em;
  height: 100%;
  height: 896px;
} */

.browser-select {
  display: flex;
  /* gap: 40px; */
}

.browser-icon {
  /* display: flex;
  margin-left: 40px; */
  gap: 10px;
}

.suite-buttons {
  display: flex;
  margin-top: 30px;
  gap: 20px;
}

.save-ts {
  width: 128px;
  height: 40px;
  border: none;
  background-color: #7eb965;
  border-radius: 5px;
  color: white;
}

.save-ts:hover{
  background-color: #5d874c;
}

.cancel-ts {
  width: 128px;
  height: 40px;
  border: none;
  background-color: #9f9f9f;
  border-radius: 5px;
  color: white;
}

.cancel-ts:hover{
  background-color: #5c5b5b;
}


/* .text-area {
 width: 844px;
} */

.text-area:hover {
  height: 100px;
}

.ts-edit {
  text-align: center;
  color: #805382;
  font-size: 18px !important;
  font: normal normal bold 16px/19px Montserrat;
}

/*responsive queries*/

@media all and (max-width:1500px) {
  .TS-Canvas-edit-head Label {
    font-size: 13.5px !important;
  }

  .TS-Canvas-edit-head{
    width: 35% !important;
  }
  .ts-edit{
    font-size: 16px !important;
  }
  .Edit-TS-ul{
    font-size: 13px !important;
  }
}

@media all and (max-width:1500px) {
  .TS-Canvas-edit-head Label, .select-ts-env option {
    font-size: 13.5px !important;
  }
}

@media screen and (max-width:1500px) {
  .TS-Canvas-edit-head select {
    height: 33px;
    font-size: 13px;
  }
}

@media screen and (max-width:1500px) {
  .TS-Canvas-edit-head .TS-edit-form {
    height: 33px !important;
    font-size: 13px !important;
  }

  .suite-buttons button {
    height: 30px !important;
    width: 110px !important;
  }
}


.form-select,.form-control{
  box-shadow: none !important;
  transition: none !important;
 border:1px solid #9F9F9F !important;
}
