* {
  font-family: Montserrat;
}

.TC-head {
  margin-top: 72px;
}


.tippy-tooltip {
  font-size: 0.8rem;
  padding: 0.3rem 0.6rem;
}

.test-row {
  position: fixed;
  background-color: #e9f5e4;
  height: 70px;
  top: 76px;
  width: 100%;
  align-items: center;
}

.TC-first-head {
  font-weight: bold;
  margin-left: 65px;
  color: #7eb965;
  font-size: 16px;
}

.Toastify__progress-bar--rtl {
    left: 10 !important;
    right: inherit !important;
    transform-origin: right !important;
    direction: ltr !important;
}

@media only screen and (min-width: 1900px) {
  .TC-first-head {
    font-size: 20px !important;
    margin-left: 72px;
  }
}

@media only screen and (min-width: 1500px) {
  .TC-first-head {
    margin-left: 72px;
  }
}

@media only screen and (min-width: 1360px) {
  .test-btns {
    margin-right: 0px !important;
  }
}

@media only screen and (max-width: 1536px) {
  .test-btns {
    margin-right: 7px !important;
  }
}

@media only screen and (max-width: 1920px) {
  .test-btns {
    margin-right: 17px !important;
  }
}

/* .bulk-btn {
  size: 30px;
} */

/* .Test-case-menu,
.test-btns,
DropdownButton {
  color: green;
}



/*
.testcase {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}*/

/* .test-row {
  width: 100%;
  height: 64px;
  margin-top: 62px;
  background-color: #7eb96526;
  color: #7eb965;
  font-size: 18px;
  display: flex;
  margin-left: 42px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
} */

/*
.menus {
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 20px;
}

.add-icon {
  margin-right: 3px;
  margin-bottom: 3px;
}
*/
.drop-select {
  border-radius: 5px;
  cursor: pointer;
  color: #fff !important;
  background-color: #7eb965;
  border: none;
  /* margin-bottom: 16px;
  margin-top: 16px; */
  text-align: start;
  outline-style: none;
  height: 32px;
  opacity: 1;
  border-radius: 5px;
  font-size: 14px;
  font-family: Montserrat;
}

/* .dept-select::after {
  content: none;
  color: green;
} */

/*
.test-drop-btn {
  background-color: white;
  width: 100%;
  height: 80px;
}
*/

.test-drop-btn {
  background-color: #7eb965;
  align-items: center;
  font-size: 12px;
  border-radius: 5px;
  padding: 5px;
  background: #65a24a;
}

.test-drop-btn:hover {
  background: #65a24a;
}

.test-case-create-btn {
  width: 150px;
  height: 30px;
  /* margin-right: 10px; */
  background-color: #7eb965;
  color: white;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border: none;
  border-radius: 5px;
  margin-top: 18px;
}

.test-case-create-btn:hover {
  background: #65a24a;
  color: white;
}

.test-case-drop-btn-ct {
  margin-left: 5px;
  margin-top: -4px;
  width: 137px;
  height: 30px;
  margin-right: 20px;
  background-color: #9F9F9F;
  color: rgb(253, 251, 251) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 12px;
  gap: 10px;
  border: none;
  border-radius: 5px;
  opacity: 1;
}

.test-case-create-btn:hover,.test-case-drop-btn-ct:hover, .test-case-drop-btn-ct:focus {
  background: #7eb965 !important;
  color: white;
  border: none;
  outline: none;
}

.dropdown-menu-light {
  width: 150px;
  margin-right: 70px;
  position: absolute;
  inset: 0px auto auto 0px;
  transform: translate(0px, 50px);
  height: 74px;
}

/*

.gen-drop-btn {
  margin-left: -16px;
}
TC-action 
.toggle {
  color: white;
}

*/

.testcase-table {
  width: 100%;
  /* padding-left: 32px; */
  margin-top: 146px;
  z-index: -10;
  position: absolute;
}

.dropdown-menu {
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;
  width: 50px;
}

@media only screen and (max-width:2100px) {
  .dropdown-menu li {
    font-size: 13px;
    font-weight: 500;
  }
}


.TC-thead th {
  font-size: 14px;
  vertical-align: middle !important;
}

td {
  /* height: 4em !important; */
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  font-weight: 500;
}

.TC-filter {
  height: 30px;
  width: 36px;
}

/* .TC-bulk-btn-App{
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  max-width: 130px !important;
  padding-inline: 15px;
  border-color: none;
  border-style: none;
  border-radius: 4px;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  background: #7eb965;
  margin-top: 18px;
  width: 130px;
  align-content: center;
  height: 30px;
} */

.TC-bulk-btn-App:hover,
.TC-bulk-btn:hover{
  background: #5D874C !important;
  color: white;
}


@media only screen and (min-width:1352px) {
  .TC-filter {
    margin-left: 65px !important;
  }
}

.TC-action-icons {
  display: flex;
  margin-right: 20px;
  gap: 25px;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1500px) {
  .TC-action-icons {
    margin-left: 5px;
    gap: 18px;
    justify-content: center;
  }

  .TC-thead th {
    font-size: 12.5px;
  }

  tbody td {
    font-size: 12px !important;
  }
}

.page-link {
  color: black;
  border: none;
  border-radius: 5px;
}

.page-link:hover {
  background-color: #5d3b5e;
  color: white;
}

.dropdown-menu {
  cursor: pointer;
  min-width: 130px;
}

.dScroll {
  max-height: 130px;
  overflow-y: auto;
  overflow-x:hidden;
  font-size: 13px;
    font-weight: 500;
    text-align: center;
  /* overflow: hidden !important; */
  /* text-overflow: ellipsis !important;
  white-space: nowrap !important;
  max-width: 130px !important; */
}

.show>.btn-success.dropdown-toggle {
  color: #fff;
  background-color: #7eb965;
  border: none;
}

.show>.btn-success.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-success {
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #7eb965;
  --bs-btn-hover-border-color: #7eb965;
}

.project-ui {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tc-loader {
  margin-top: 20%;
}

/* .tc-not {} */

/* 
.gen-create-button {
  margin-left: 5px;
  width:137px;
  height: 30px;
  margin-right: 20px;
  margin-bottom: -3px;
  background-color: #7eb965;
  color: white;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  gap: 10px;
  border: none;
  border-radius: 5px;
  opacity: 1;
}

.gen-create-button:hover {
  background: #65a24a;
  color: white;
} */

.TC-bulk,
.TC-App,
.TC-suite {
  font-size: 13px !important;
  color: #7eb965;
  font-weight: bold;
}

/* .TC-bulk {
  margin-left: 17px !important;
} */

.TC-bulk-btn {
  border-color: none;
  border-style: none;
  border-radius: 4px;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  background: #7eb965;
  margin-top: 18px;
  /* width: 130px; */
  align-content: center;
  height: 30px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  min-width: 150px !important;
  padding-inline: 15px;
}

.TC-action {
  margin-left: 70px !important;
  padding-top:20px !important;
}

.testcase-table td{
  vertical-align: middle;
}

.tc-not-found{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top:20%;
}

.dropdown-item{
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  /* min-width: 150px !important; */
  padding-inline: 15px !important;
}

.test-btns .dropdown-item:hover{
  background-color: #7eb965 !important;
  color: #fff;
}

.TS-app-btn{
  background-color: transparent;
  border: none;
  color: #fff;
  max-width: 100px;
  min-width: 100px;
  overflow-x: hidden;
}

.TC-drop-menu .TC-drop{
min-width: 150px !important;
}

.TC-app .TC-drop-app{
  min-width: 140px !important;
  max-width: 140px !important;
}

.CTC_ddown .dropdown-item:hover{
  border-radius:5px !important;
}

.TC-app .dropdown-menu{
  min-width: 150px;
}

.drop-TS .TC-bulk-btn{
  min-width: 140px;
}

.drop-TS .dropdown-menu{
  min-width: 140px;
}
/* pagination */
.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root{
  border: 1px solid #F0E3F1 !important;
}


.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root:hover {
  background-color: #805382 !important;
  color:white;
}


.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected{
  background-color: #5D3B5E !important;
  color:white;

}

.page-head{
  margin-left:60px;
  padding: 20px;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media only screen and (max-width: 1500px) {
  .page-head {
     margin-left:50px;
     padding: 7px;
  }
}

