* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.testcase-edit {
  width: 100%;
}

.title {
  margin-left: 112px;
  margin-top: 38px;
  font: normal normal bold 18px/22px Montserrat;
  color: "#805382";
}

 .TC-cln-user-story-menus .error {
  color: red;
  font-size: 0.9rem;
  font-weight: 500;
  margin-top: 0.3rem;
  display: flex;
  justify-content: flex-start;
}

.CLTC-error{
  color: red !important;
  font-size: 0.9rem;
  font-weight: 500;
  margin-top: 0.3rem;
  display: flex;
  justify-content: flex-start;
}

.drop {
  display: flex;
  justify-content: center;
  margin-top: 0;
  gap: 20px;
  border: none;
  align-items: center;
  margin-left: auto;
}

/* .clone-form {
  width: 100% !important;
} */

/* 
    .input {
      margin-left: 112px;
      margin-top: 48px;
      width: 560px;
      height: 38px;
    } */
.menu {
  width: 560px;
  height: 38px;
}

.btn {
  /* margin-left: 365px; */
  border: 1px #9f9f9f solid;
}

/* .clone-create-test-btn {
  margin-left: 5px;
  width: 160px;
  height: 30px;
  margin-right: 20px;
  background-color: #7eb965;
  color: white;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  gap: 10px;
  border: none;
  border-radius: 5px;
  opacity: 1;
}

.clone-create-test-btn:hover {
  background: #65a24a;
  color: white;
} */

.testcase-editscreen {
  display: flex;
  gap: 40px;
  margin-top: 40px;
  width: 1168px;
}

.top-info {
  display: flex;
  gap: 50px;
}

.editinfo-buttons {
  display: flex;
  margin-left: 100px;
  margin-right: 40px;
}

.editscreen-buttons {
  display: flex;
  margin-left: 50px;
  gap: 20px;
}

.ts-buttons {
  border: none;
  border-radius: 5px;
  height: 40px;
  width: 148px;
}

.left-input {
  width: 600px;
}

/* .test-editor {
      border: 1px #9f9f9f solid;
      margin-left: 740px;   
       width: 1168px;
      height: 648px;
    } */
/* .publish-buttons {
      margin-left: 1378px;
      margin-top: 39px;
      margin-bottom: 80px;
      display: flex;
      gap: 20px;
    } */
.editor-button {
  display: flex;
  /* gap: 50px; */
}

.edit-screen-buttons {
  display: flex;
  margin-left: 10px;
  width: 1168px;
  gap: 20px;
}

@media all and (max-width:1500px) {
  .TC-cln-user-story-menus Label {
    font-size: 13.5px !important;
  }
}

@media screen and (max-width:1500px) {
  .TC-cln-user-story-menus select {
    height: 33px;
  }
}

@media screen and (max-width:1500px) {
  .TC-cln-user-story-menus .clone-form {
    height: 33px !important;
    font-size: 13px !important;
  }
}

.cln-first-head {
  font-weight: bold;
  margin-left: 75px;
  color: #7eb965;
}

@media only screen and (max-width: 1200px){
  .cln-first-head{
    font-size: 15px !important;
  }
}

@media only screen and 
(min-width: 1900px){
.cln-first-head{
  font-size: 20px;
}  
}

@media only screen and (max-width:1500px) {

  .cln-first-head,
  .TC-cln-sec-head {
    margin-left: 65px !important;
  }
}

.clone-editor-head {
  color: #000;
  font-weight: bold;
  margin-top: 10px;
  padding: 0;
}

.cln-react-editors {
  font-family: Montserrat;
  font-size: 14px;
  border: 1px #9f9f9f solid;
  border-radius: 5px;
  font-size: 14px;
  width: 100%;

}

@media all and (max-width:1500px) {
/* .Editor-bottom-btns button{
  height: 30px !important;
  width: 110px !important;
font-size: 13px;
border-style: none;
} */
  .clone-editor-head {
    font-size: 14px;
  }
}

.Editor-bottom-btns button,.clt-run-button{
  height: 30px !important;
  width: 110px !important;
  font-size: 13px;
  border-style: none;
  cursor:pointer;
}

.clonetest_test-row {
  position: fixed;
  background-color: #e9f5e4;
  height: 70px;
  top: 76px;
  width: 100%;
  align-items: center;
  z-index: 2;
}

.TC-cln-sec-head {
  color: #805382;
  font-weight: bold;
  margin-left: 75px;
}

@media all and (max-width: 1500px) {
  .TC-cln-sec-head {
    margin-left: 65px;
  }
}

.clone_test_Cfluid {
  margin-top: 160px;
}

@media all and (max-width:1350px) {
  .clone_test_Cfluid {
    margin-left: 0px;
  }
}

.clonetest_container {
  margin-top: 20px;
}

.TC-cln-user-story-menus {
  margin-left: 27px;
  margin-top: 10px;
}


.TC-cln-user-story-menus ,
Form.Label {
  font-weight: bold;
  font-size: 15px;
}


@media all and (max-width:1500px) {
  .TC-cln-user-story-menus {
    margin-left: 18px !important;
  }
}

/* @media all and (max-width:1350px) {
  .TC-cln-user-story-menus {
    margin-left: 10px;
  }
} */

.edit-clone, .editor-code{
  height: 95%;
}

.ct-edit-save-as {
  color: #fff;
  font-weight: bold;
  background: #805382;
  border-color: none;
  border-radius: 4px;

}

.ct-edit-save-as:hover {
  color: #fff;
  font-weight: bold;
  background-color: #5D3B5E !important;
}

.ct-edit-publish,.clt-run-button {
  border-color: none;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  background: #7eb965 !important; 
  padding: 6px 25px;
}

.ct-edit-publish:hover {
  border-color: none;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  background-color: #5d874c !important;
}

.ct-edit-cancel {
  border-color: none;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  background: rgb(179, 177, 177) !important;
  padding: 6px 25px;
}

.ct-edit-cancel:hover {
  border-color: none;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  background-color: #5c5b5b !important;
}

.clt-run-button {
  display: flex;
  gap: 5px;
  color: white;
  align-items: center;
  justify-content: center;
  background-color: #7eb965;
  border: none;
  border-radius: 5px;
  width: 128px;
  height: 40px;
}

.clt-run-button:hover{
  background-color: #5d874c !important;
}
