.create-sh-canvas{
    background-color: #f0e3f1; 
    margin-top: 11.8rem !important;
    width: 40% !important;
    margin-left: 40px;
    position: fixed;
    overflow-y: scroll;
  overflow-x: hidden;
  min-height: 40vh !important;
}

.user-canvas .edit-sh-canvas .error {
  color: red;
  font-size: 0.9rem;
  font-weight: 500;
  margin-top: 0.3rem;
  display: flex;
  justify-content: flex-start;
  margin-left: 2.7rem !important;
}

@media all and (max-width:1500px){
  .create-sh-canvas{
    width: 35% !important;
  }
    .create-stake-button{
      margin-right: 30px !important ;
    }

    .create-sh-button{
      width: 100px !important;
      font: normal normal normal 13px/22px Montserrat !important;
    }
    .cancel-sh-button{
      width: 100px !important;
      font: normal normal normal 13px/22px Montserrat !important;
    }

    .sh-story-box{
      height: 33px !important;
  font-size: 13px !important;
  }
  .create-sh-label{
      font-size: 13px !important;   
  } 
  .create-sh{
    font: normal normal bold 15px/19px Montserrat !important;
  }
}

.sh-story-box {
    border: 1px solid #9f9f9f;
    border-radius: 5px;
    width: 85%; 
    margin-left: 40px;
    font-size: 14px;
}


.create-stake-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 50px;
    margin-top: 20px;
    gap: 10px;
    margin-bottom: 10px !important;
}

.create-sh-button {
  background-color: #7eb965;
  width: 120px;
  font: normal normal normal 15px/22px Montserrat;
  color: white;
  padding: 5px;
  border: none;
  border-radius: 5px;
  margin-left: 10px;
  }
  
  .create-sh-button:hover {
    background-color: #5d874c;
  }


.cancel-sh-button {
  width: 120px;
  background-color: #999999;
  font: normal normal normal 15px/22px Montserrat;
  color: white;
  padding: 5px;
  border: none;
  border-radius: 5px;
}

.cancel-sh-button:hover {
    background-color: #5c5b5b;
}

.create-sh{
    width: 100%;
  color: #805382;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  font-size: 16px;
  font: normal normal bold 16px/19px Montserrat;
  opacity: 1;
}


.sh-create-btn::after {
    content: none;
}

.create-sh-btn{
  width: 150px;
  height: 30px;
  margin-right: 15px;
  background-color: #7eb965;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  border: none;
  border-radius: 5px;
  font-weight: normal;
}

.create-sh-btn:hover{
  background-color: #5d874c !important;
  /* color: black; */
}

.create-sh-label{
    margin-left: 40px; 
    font-weight: bold;
  }