@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/css/bootstrap.min.css");

body {
  margin: 0;
  font-family: Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.form-select,.form-control{
  box-shadow: none !important;
  transition: none !important;
 border:1px solid #9F9F9F !important;
}
::-webkit-scrollbar {
  width: 7px;
 height: 5px;
  
  }
  
  
  
  
  /* Track */
  
  ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  }
  
  
  
  
  /* Handle */
  
::-webkit-scrollbar-thumb {
background: rgb(139, 139, 139);
border-radius: 10px;
}
  
  
  
  
  /* Handle on hover */
  
::-webkit-scrollbar-thumb:hover {
background: grey;
}

  textarea {
    border: none;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none; /*remove the resize handle on the bottom right*/
}


.dropdown-item.active{
  background-color:#805382 !important;
  color:white !important;
}

